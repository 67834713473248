importScripts('/__/firebase/4.8.1/firebase-app.js');
importScripts('/__/firebase/4.8.1/firebase-messaging.js');
importScripts('/__/firebase/init.js');

const messaging = firebase.messaging();
messaging.setBackgroundMessageHandler(function(payload) {
    console.log('[firebase-messaging-sw.js] Received background message ', payload);
    const notificationTitle = 'Background Message Title';
    const notificationOptions = {
      body: 'Background Message body.',
      icon: '/assets/img/logo.png'
    };
  
    return self.registration.showNotification(notificationTitle,
        notificationOptions);
});